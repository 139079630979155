// const currentTheme = 'first'
const currentTheme = 'second'

const theme = currentTheme === 'first' ? '#396B7C' : '#004150';
const softTheme = currentTheme === 'first' ? '#CCE0EC' : '#7fd0dd';
const profileSoftTheme = currentTheme === 'first' ? '#3a6b7c' : '#004150';

export default {
    // theme: '#396B7C',
    theme,
    softTheme,
    profileSoftTheme,
    bodyBg: '#F2F1F1',
    babyBlue: '#74B8DB',
    grey: '#343A40',
    paleText: 'rgba(255,255,255,0.7)',
    greyText: '#b2b2b2',
    paleBorder: '#CDD4D9',
    profileHard: '#05495a',
    themeHard: '#396B7C',
}