import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import logo from "../../assets/shared/logo/logo.png";
import magikkdigital from "../../assets/magikk-logo-digital/magikk-logo-digital.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setLang } from "../../redux/Auth/auth.actions";
import i18next from "i18next";
import Toastr from "../Shared/Toastr";

export const AuthHeader = ({ bg, toastrTxt }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [showLangPopUp, setShowLangPopUp] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const [currentLang, setCurrentLang] = useState("pt");

  useEffect(() => {
    const language = localStorage.getItem("lang");
    // console.log("language : ", language);
    // console.log("currentLang : ", currentLang);

    if (language) {
      setCurrentLang(language);
    }
  }, []);

  return (
    <div
      className={`flex flex-row items-center container mx-auto font-VolksMedium py-8 ${
        bg === "dark" ? "text-white" : ""
      }`}
    >
      <div className="fixed shadow-md w-full md:w-12/12 top-24 right-24 z-50">
        {toastrTxt && <Toastr toastrTxt={toastrTxt} />}
        {/* {volunteeringMessage && <Toastr toastrTxt={volunteeringMessage} />} */}
      </div>

      <div className="w-3/4 md:w-full flex ">
  <img
    onClick={() => history.push("/")}
    src={bg === "dark" ? magikkdigital : logo}
    alt={"logo"}
    className="btn-effects w-44"
  />
  <ul className="hidden md:flex flex-row mx-auto justify-around space-x-4">
    <li
      onClick={() => {
        history.push("/market");
      }}
      className="btn-effects text-hover"
    >
      {t("login.market")}
    </li>
    <li
      onClick={() => {
        history.push("/i-am-ngo");
      }}
      className="btn-effects text-hover"
    >
      {t("login.i_am_ngo")}
    </li>
    <li
      onClick={() => {
        history.push("/i-am-company");
      }}
      className="btn-effects text-hover"
    >
      {t("login.i_am_company")}
    </li>
    <li
      className={`px-8 py-2 border-r btn-effects flex flex-1 border-none`}
    >
      <div
        className={`uppercase w-8 ${bg === "dark" ? "text-white" : "text-black"
          }`}
        onClick={() => {
          setShowLangPopUp(!showLangPopUp);
        }}
      >
        {currentLang || "TT"}
      </div>
      {showLangPopUp && (
        <div className="z-20 hover:z-21 -ml-6 absolute flex flex-col border-primaryHard border-2 px-6 py-2 rounded-md mt-6">
          <div
            className="mb-2 pb-2 border-b border-black text-hover"
            onClick={() => {
              i18next.changeLanguage("pt");
              localStorage.setItem("lang", "pt");
              setCurrentLang("pt");
              setShowLangPopUp(!showLangPopUp);
            }}
          >
            PT
          </div>
          <div
            onClick={() => {
              i18next.changeLanguage("en");
              localStorage.setItem("lang", "en");
              setCurrentLang("en");

              setShowLangPopUp(!showLangPopUp);
            }}
            className="text-hover"
          >
            EN
          </div>
        </div>
      )}
    </li>

    <li
      onClick={() => {
        history.push("/login");
      }}
      className={`btn-effects ${bg === "dark" ? "bg-secondary" : "bg-primary"
        } rounded-lg px-12 py-2 -mr-4 ${bg === "dark" ? "text-primary" : "text-white"
        }`}
    >
      {t("login.sign_in")}
    </li>
    <li
      onClick={() => {
        history.push("/register");
      }}
      className={`btn-effects ${bg === "dark" ? "bg-white" : "bg-secondary"
        } rounded-lg px-12 py-2 text-primary`}
    >
      {t("login.sign_up")}
    </li>
  </ul>
</div>
     {/* Mobile navigation */}
      <div className="md:hidden flex items-center w-1/4 ">
      <div
        className={`uppercase w-8 ${bg === "dark" ? "text-white" : "text-black"
          }`}
        onClick={() => {
          setShowLangPopUp(!showLangPopUp);
        }}
      >
        {currentLang || "TT"}
      </div>
      {showLangPopUp && (
        <div className="z-20 hover:z-21 -ml-6 absolute flex flex-col border-primaryHard border-2 px-6 mt-36 py-2 rounded-md ">
          <div
            className="mb-2 pb-2 border-b border-black text-hover"
            onClick={() => {
              i18next.changeLanguage("pt");
              localStorage.setItem("lang", "pt");
              setCurrentLang("pt");
              setShowLangPopUp(!showLangPopUp);
            }}
          >
            PT
          </div>
          <div
            onClick={() => {
              i18next.changeLanguage("en");
              localStorage.setItem("lang", "en");
              setCurrentLang("en");

              setShowLangPopUp(!showLangPopUp);
            }}
            className="text-hover"
          >
            EN
          </div>
        </div>
      )}
        <button
          className="text-2xl"
          onClick={() => {
            setMobileNavOpen(!mobileNavOpen);
          }}
        >
          &#9776;
        </button>
      </div>

      {mobileNavOpen && (
  <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-10 flex flex-row-reverse justify-items-end items-start pt-10 pr-10">
    <div className="bg-white rounded p-4">
      <button
        onClick={() => {
          setMobileNavOpen(false);
        }}
        className="absolute top-0 right-0 pr-10 pt-4 focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-gray"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <ul className="flex flex-col space-y-4">
        <li
          onClick={() => {
            history.push("/market");
          }}
          className="btn-effects text-hover"
        >
          {t("login.market")}
        </li>
        <li
          onClick={() => {
            history.push("/i-am-ngo");
            setMobileNavOpen(false);
          }}
          className="btn-effects text-hover"
        >
          {t("login.i_am_ngo")}
        </li>
        <li
          onClick={() => {
            history.push("/i-am-company");
            setMobileNavOpen(false);
          }}
          className="btn-effects text-hover"
        >
          {t("login.i_am_company")}
        </li>
        <li
          onClick={() => {
            history.push("/login");
            setMobileNavOpen(false);
          }}
          className={`btn-effects ${
            bg === "dark" ? "bg-secondary" : "bg-primary"
          } rounded-lg px-12 py-2 ${
            bg === "dark" ? "text-primary" : "text-white"
          }`}
        >
          {t("login.sign_in")}
        </li>
        <li
          onClick={() => {
            history.push("/register");
            setMobileNavOpen(false);
          }}
          className={`btn-effects ${
            bg === "dark" ? "bg-white" : "bg-secondary"
          } rounded-lg px-12 py-2 text-primary`}
        >
          {t("login.sign_up")}
        </li>
      </ul>
    </div>
  </div>
)}
    </div>
    
  );
};

  const mapStateToProps = ({ auth, profile, market }) => ({
    currentLang: auth.lang,
    toastrTxt: profile.toastrTxt,
  });

  const mapDispatchToProps = (dispatch) => ({
    dispatchSetLang: (lang) => dispatch(setLang(lang)),
  });

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthHeader);
