import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import logo from "../assets/shared/logo/logo.png";
import magikkdigital from "../assets/magikk-logo-digital/magikk-logo-digital.png";
import { useHistory } from "react-router-dom";
import { FaList, FaSearch, FaShoppingCart } from "react-icons/fa";
import fakeAvatar from "../assets/avatar.jpg";
import Toastr from "../components/Shared/Toastr";
import { setLang } from "../redux/Auth/auth.actions";
import { handleGetSearchData } from "../redux/Market/market.helpers";
import { useTranslation } from "react-i18next";
import { SearchResults } from "./Market/Search/SearchResults";

export const MainHeader = ({
  bg,
  user,
  search,
  toastrTxt,
  dispatchSetLang,
  currentLang,
}) => {
  const { t, i18n } = useTranslation();
  const [showLangPopUp, setShowLangPopUp] = useState(false);
  const [cartCount, setCartCount] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const history = useHistory();
  const avatar =
    !user?.avatar ||
      (user?.avatar && user.avatar.startsWith("magikk"))
      ? fakeAvatar
      : `${process.env.REACT_APP_API_URI}/${user?.avatar}`;

  useEffect(() => {
    const result = localStorage.getItem("cart");
    if (result) {
      var array = JSON.parse(result);
      setCartCount(array.length);
    }
  }, []);

  const handleChangeSearch = async (e) => {
    const val = e.target.value;

    if (val.length < 2) {
      setSearchResults([]);
      return;
    }
    const results = await handleGetSearchData(val);
    let allRes = [];
    const { user, organizations, products, causes } = results;

    user?.map((u) => {
      allRes?.push({
        txt: `${u.firstname} ${u.lastname}`,
        type: "User",
        id: u.id,
      });
    });

    organizations.map((o) => {
      allRes.push({
        txt: o.name.trim(),
        type: "Organization",
        id: o.id,
      });
    });

    products.map((p) => {
      allRes.push({
        txt: p.title.trim(),
        type: "Product",
        id: p.id,
      });
    });

    causes.map((c) => {
      allRes.push({
        txt: c.title.trim(),
        type: "Cause",
        id: c.id,
      });
    });

    function compare(a, b) {
      if (a.txt < b.txt) {
        return -1;
      }
      if (a.txt > b.txt) {
        return 1;
      }
      return 0;
    }

    allRes.sort(compare);
    setSearchResults(allRes);
  };

  return (
    <>
      <div className="fixed shadow-md w-8/12 md:w-4/12 top-24 right-12 z-50">
        {toastrTxt && <Toastr toastrTxt={toastrTxt} />}
      </div>
      <div
        className={`flex justify-between flex-row items-center container mx-auto font-VolksMedium py-8 ${bg === "dark" ? "text-white" : ""
          }`}
      >
        <img
          onClick={() => {
            history.push("/");
          }}
          src={bg === "dark" ? magikkdigital : logo}
          alt={"logo"}
          className="btn-effects w-44 hidden md:block"
        />
        {search && (
          <div className="hidden md:flex bg-white w-full md:w-4/12 pl-6 mx-auto h-12 rounded-2xl flex flex-row items-center px-1 py-1 border-2 relative">
            <FaSearch size="22" color="whitesmoke" />
            <input
              onChange={handleChangeSearch}
              placeholder={t("market.search")}
              type="text"
              className="flex-1 ml-6 outline-none"
            />
            <div
              onClick={() => {
                history.push("/", { data: "open" });
              }}
              className="bg-primary flex flex-row p-4 h-full text-white items-center rounded-xl btn-effects"
            >
              <FaList size="22" color="white" />
              <span className="ml-4 font-OpenSansRegular text-sm">
                {t("market.categ")}
              </span>
            </div>
            <SearchResults items={searchResults} style={"w-full top-12"} />
          </div>
        )}
        <ul
          className={`flex flex-row items-center relative ${bg === "dark" ? "text-white" : "text-black-500"
            }`}
        >
          <li
            onClick={() => {
              user
                ? history.push("/profile")
                : history.push("/login");
            }}
            className={`btn-effects items-center rounded-lg px-6 py-2 -mr-4 flex flex-row`}
          >
            <img
              src={avatar}
              className="mr-5 rounded-bodyxl w-10 h-10"
              alt="avatar"
            />

            <p className="text-hover *">
              {user
                ? `${user?.firstname} ${user?.lastname}`
                : "Login/Register"}
            </p>
          </li>
          <li className={`px-8 py-2 border-r btn-effects`}>
            <div
              className="uppercase w-8"
              onClick={() => {
                setShowLangPopUp(!showLangPopUp);
              }}
            >
              {currentLang}
            </div>
            {showLangPopUp && (
              <div className="-ml-6 absolute flex flex-col border-primaryHard border-2 p-6 rounded-md mt-4">
                <div
                  className="mb-2 pb-2 border-b border-black text-hover"
                  onClick={() => {
                    dispatchSetLang("pt");
                    setShowLangPopUp(!showLangPopUp);
                  }}
                >
                  PT
                </div>
                <div
                  onClick={() => {
                    dispatchSetLang("en");
                    setShowLangPopUp(!showLangPopUp);
                  }}
                  className="text-hover"
                >
                  EN
                </div>
              </div>
            )}
          </li>
          <li
            onClick={() => {
              history.push("/cart");
            }}
            className={`btn-effects rounded-lg px-8 py-2`}
          >
            <FaShoppingCart size="25" />
            {!!cartCount && cartCount !== "0" && (
              <div className="absolute top-0 right-4 bg-greyText rounded-full w-6 h-6 items-center justify-center text-center">
                {cartCount}
              </div>
            )}
          </li>
        </ul>
             {/* Mobile navigation */}
          <div className="md:hidden flex items-center w-1/4 ">
        <button
          className="text-2xl"
          onClick={() => {
            setMobileNavOpen(!mobileNavOpen);
          }}
        >
          &#9776;
        </button>
      </div>
      {mobileNavOpen && (
  <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 z-10 flex flex-row-reverse justify-items-end items-start pt-10 pr-10">
    <div className="bg-white text-primary rounded p-4">
      <button
        onClick={() => {
          setMobileNavOpen(false);
        }}
        className="absolute top-0 right-0 pr-10 pt-4 focus:outline-none"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <ul className="flex flex-col space-y-4">
        <li
          onClick={() => {
            history.push("/market");
          }}
          className="btn-effects text-hover"
        >
          {t("login.market")}
        </li>
        <li
          onClick={() => {
            history.push("/i-am-ngo");
            setMobileNavOpen(false);
          }}
          className="btn-effects text-hover"
        >
          {t("login.i_am_ngo")}
        </li>
        <li
          onClick={() => {
            history.push("/i-am-company");
            setMobileNavOpen(false);
          }}
          className="btn-effects text-hover"
        >
          {t("login.i_am_company")}
        </li>
        
      </ul>
    </div>
  </div>
)}
      </div>
    </>
  );
};

const mapStateToProps = ({ auth, profile }) => ({
  user: auth.user,
  toastrTxt: profile.toastrTxt,
  currentLang: auth.lang,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetLang: (lang) => dispatch(setLang(lang)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainHeader);
