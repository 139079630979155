import React from "react";
import fakeAvatar from "../../assets/avatar.jpg";
import { useHistory } from "react-router-dom";

function Toastr({ toastrTxt }) {
  const history = useHistory();

  const clickEvent = () => {
    if (
      toastrTxt?.txt &&
      toastrTxt?.txt?.indexOf("your card") !== -1
    ) {
      // return ()=>{
      history.push("/cart");
      // }
    }
  };
  if (!toastrTxt) return null;
  return (
    <div
      onClick={
        toastrTxt.clickEvent ? toastrTxt.clickEvent : clickEvent
      }
      className={
        "btn-effects flex flex-row bg-white rounded-md overflow-hidden p-3 border-l-16px border shadow-md " +
        (toastrTxt?.err ? "border-red-500" : "border-green-500")
      }
    >
      {/* <img className="w-1/12 mr-4" src={fakeAvatar} alt="fake avatar"/> */}
      <div className="flex flex-col text-black">
        <p className="font-VolksMedium font-bold">
          {toastrTxt?.title}
        </p>
        <p className="font-LatoRegular">{toastrTxt?.txt}</p>
      </div>
    </div>
  );
}

export default Toastr;
