import React, { useState, useEffect } from "react";
import MainHeader from "../components/MainHeader";
import { connect, useSelector } from "react-redux";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";

import {
  startCartPayment,
  startMakeDonation,
  startMakePix,
  setToastrTxt,
  setPixResult, clearCart,
} from "../redux/Cart/cart.actions";
import { BiTransfer } from "react-icons/bi";
import colors from "../constants/colors";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import {
  handleGetDeliveryInfo,
  handleUpdateDeliveryInfo,
} from "../redux/Cart/cart.helpers";

import loaderGif from "../assets/spinner.gif";

import { checkCurrentUser } from "../redux/Auth/auth.actions";

function Donation({
  cause,
  cartPayment,
  currentLang,
  makeDonation,
  makePix,
  cart,
  profileData,
  dispatchCheckCurrentUser,
  dispatchSetToastrTxt,
  dispatchClearCart
}) {
  const { t, i18n } = useTranslation();

  document.title = "magikk - doação";

  const history = useHistory();
  const imageSource = cause?.images?.length && cause?.images[0].url;
  const [donationAmount, setDonationAmount] = useState(5);
  const [state, setState] = useState({});
  const [subState, setSubState] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [cpf, setCPF] = useState(null);
  const [postalCode, setPostalCode] = useState(null);
  const [phone, setPhone] = useState(null);
  const [addressNo, setAddressNo] = useState(null);
  const [address, setAddress] = useState(null);
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('pix');
  const pixResult = useSelector((state) => state?.cart?.pix_result?.data?.data || null);

  function cc_format(value) {
    //console.log(value);
    const temp = value.replace(/\W/gi, "").replace(/(\d{4})/g, "$1 ");
    if (value.length % 4 == 0) {
      return temp.slice(0, -1);
    }
    return temp;
  }

  const triggerToastr = ({ toastrTxt, err }) => {
    dispatchSetToastrTxt({
      title: t("toastr.donation"),
      txt: toastrTxt,
      err: err,
    });
    setTimeout(() => {
      dispatchSetToastrTxt();
    }, 3500);
  };

  const onSubmitDonation = () => {
    setLoading(true);
    const expire_month = state.date ? state.date.split("/")[0] : "";
    const expire_year = state.date ? state.date.split("/")[1] : "";

    let data = {
      card_info: {
        save_card_id: "",
        // holder_name: "Michael Ericson ",
        holder_name: state.holder_name,
        number: state.number,
        expire_year: expire_year,
        expire_month: expire_month,
        cvv: state.cvv,
        subscription: subState,
      },
      payment_method: paymentMethod,
      price: donationAmount,
    };

    if (paymentMethod === 'pix') {

      data = {
        payment_method: paymentMethod,
        data: pixResult,
        price: pixResult?.value,
      };

    }

    makeDonation({
      data: {
        id: cause?.id,
        data: data,
      },
      language: currentLang,
    });
    setLoading(false);
  };

  useEffect(async () => {
    handleGetDeliveryInfo()
      .then((delivery_info) => {
        setAddress(delivery_info?.address);
        setCPF(delivery_info?.document);
        setCity(delivery_info?.city);
        setAddressNo(delivery_info?.number);
        setPhone(delivery_info?.phone);
        setPostalCode(delivery_info?.postal_code);
        setLoading(false);

        if (
          !delivery_info?.number ||
          !delivery_info?.city ||
          !delivery_info?.phone ||
          !delivery_info?.document ||
          !delivery_info?.postal_code ||
          !delivery_info?.address
        ) {
          setShowEditProfile(true);
        }

        if (cart.donation_result && cart.donation_result.data) {

            dispatchClearCart();

          history.push("/donation-success", {
            data: {
              price_amount: donationAmount,
              karma_amount: donationAmount,
            },
          });
        }
      })
      .catch((_) => {
        setLoading(false);
      });
    // console.log("delivery", delivery_info);
  }, [cart]);

  const onSubmitPix = () => {
    setLoading(true);


    const data = {
      payment_method: paymentMethod,
      price: donationAmount,
      cause_id: cause?.id,
    };

    makePix({
      data: {
        id: cause?.id,
        data: data,
      },
      language: currentLang,
    });
    setLoading(false);
  };

  return (
    <div className="bg-bodyBg">
      <MainHeader search={true} />
      <div className="mx-auto md:px-48 flex flex-col md:flex-row">
        <div className="w-full md:w-8/12 flex flex-col">
          <div className="font-VolksMedium text-3xl md:text-3xl mb-8 flex flex-row items-center justify-between">
            <div className="w-full md:w-3/12 whitespace-nowrap">{t("cart.donation")}</div>
            <div className="border-t w-full md:w-9/12 border-black"></div>
          </div>
          <div>
            <div className="bg-white w-full rounded-ftxl p-4 pb-12">
              <div className="w-full flex flex-row justify-between flex-nowrap">
                <img
                  src={imageSource}
                  className="w-1/2 rounded-ftxl"
                />
                <div className="w-full flex flex-col justify-center overflow-hidden p-4">
                  <h2 className="whitespace-nowrap font-VolksMedium text-3xl">
                    {cause?.title}
                  </h2>
                  <h4 className="text-lg font-LatoRegular underline">
                    {cause?.user?.firstname} {cause?.user?.lastname}
                  </h4>
                </div>
              </div>
              <div className="border-t border-b border-t-2 w-full border-bodyBg my-8"></div>
              <div>
                <div className="justify-between flex flex-row font-VolksDemiBold text-xl mb-2">
                  <h4>{t('donation.send')}</h4>
                  <h4>{t('donation.get')}</h4>
                </div>
                <div className="font-VolksDemiBold bg-bodyBg rounded-2xl w-full flex flex-row justify-between p-6 text-xl relative max-h-16 items-center">
                  {/* <h4>$ 45</h4> */}
                  <div className="flex flex-row w-2/5">
                    <h4 className="text-gray-500">R$ </h4>
                    <input
                      type="text"
                      className="bg-transparent ml-4 border-b border-secondary text-black pl-4"
                      placeholder=" 5 "
                      onChange={(e) => {
                        let txt1 = e.target.value.replace(
                          /[^0-9\/]/g,
                          ""
                        );
                        const intTxt = parseInt(txt1) || 0;
                        setDonationAmount(intTxt);
                      }}
                      value={donationAmount}
                    />
                  </div>
                  <div className="flex items-center justify-center bg-secondary w-20 h-20 rounded-xl">
                    <BiTransfer size={48} color={colors.theme} />
                  </div>
                  <h4 className="w-2/5 text-right">
                    {donationAmount} kk
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6 md:py-10 text-white font-VolksMedium w-full md:w-5/12 bg-primary md:ml-10 rounded-kkxl flex flex-col items-center justify-between">
          <div className="px-4 pt-10 text-white font-VolksMedium w-full bg-primary rounded-kkxl flex flex-col items-center justify-between">
            {
              showEditProfile ? 
              <div class="flex flex-col justify-start w-full mb-4">
                {t("donation.incomplete_info")} 
                <button className={`btn-1 bg-secondary btn-effects text-primary font-VolksDemiBold text-base ml-auto mt-4`} onClick={() => history.push("/account-settings")}>{t("button.update_info")}</button>
              </div>
              :
              <>
                <div className="w-full">
                  <h4 className="font-LatoRegular text-sm">
                    {t("your_profile.document")} : {cpf}
                  </h4>
                </div>
                <div div className="w-full">
                  <h4 className="font-LatoRegular text-sm">
                    {t("your_profile.phone")} : {phone}
                  </h4>
                </div>
                <div className="mr-auto text-xl mt-4 mb-4">
                  {t("donation.title_address")}
                </div>
                <div div className="w-full">
                  <h4 className="font-LatoRegular text-sm">
                    {t("your_profile.pk")} : {postalCode}
                  </h4>
                </div>
                <div div className="w-full">
                  <h4 className="font-LatoRegular text-sm">
                    {t("your_profile.address")} : {address}
                  </h4>
                </div>
                <div div className="w-full">
                  <h4 className="font-LatoRegular text-sm">
                    {t("your_profile.city")} : {city}
                  </h4>
                </div>
                <div div className="w-full">
                  <h4 className="font-LatoRegular text-sm">
                    {t("your_profile.no")} : {addressNo}
                  </h4>
                </div>
                <div className="flex flex-col justify-start w-full mb-4">
                  <button className={`btn-1 bg-secondary btn-effects text-primary font-VolksDemiBold text-base ml-auto mt-4`} onClick={() => history.push("/account-settings")}>{t("button.update_info")}</button> 
                </div>
              </>
            }
          </div>

          <div className="px-4 pt-10 text-white font-VolksMedium w-full bg-primary rounded-kkxl flex flex-col items-center justify-between">
            <select
              className="input-classic w-full text-black pl-4"
              value={paymentMethod || "pix"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <option value="pix">PIX</option>
              <option value="creditCard">{t('cart.card')}</option>

            </select>

            {paymentMethod === "creditCard" && (
              <div className="w-full">
                <div className="mr-auto text-xl mb-4">{t("cart.card")}</div>
                <div className="flex flex-col justify-start mb-6 w-full">
                  <h4 className="font-LatoRegular text-sm">
                    {t("cart.card_name")}
                  </h4>
                  <input
                    className="input-classic w-full text-black pl-4"
                    placeholder="John Doe"
                    onChange={(e) => {
                      const value = e.target.value;
                      setState({
                        ...state,
                        holder_name: value,
                      });
                    }}
                  />
                </div>
                <div className="flex flex-col justify-start mb-6 w-full">
                  <h4 className="font-LatoRegular text-sm">
                    {t("cart.card_number")}
                  </h4>
                  <input
                    className="input-classic w-full text-black pl-4"
                    placeholder="1234 5678 1234 5678"
                    value={state.number || ""}
                    onChange={(e) => {
                      const value = e.target.value;
                      let text = value.replace(/[^0-9]/g, "");
                      if (text.length < 17) {
                        text = cc_format(text);
                        setState({
                          ...state,
                          number: text,
                        });
                      }
                    }}
                  />
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col justify-start mb-6 w-4/12">
                    <h4 className="font-LatoRegular text-sm whitespace-nowrap">
                      {t("cart.card_expire")}
                    </h4>
                    <input
                      className="input-classic w-full text-black pl-4"
                      placeholder="01/22"
                      onChange={(e) => {
                        var txt = e.target.value;
                        var txt1 = txt;
                        if (txt.length < 6) {
                          if (
                            txt1.length === 2 &&
                            state.date.length < txt.length
                          ) {
                            txt1 = txt1 + "/";
                          }

                          setState({
                            ...state,
                            date: txt1,
                          });
                        }
                      }}
                      value={state.date || ""}
                    />
                  </div>
                  <div className="flex flex-col justify-start mb-6 w-6/12">
                    <h4 className="font-LatoRegular text-sm">{t("cart.card_cvv")}</h4>
                    <input
                      className="input-classic w-full text-black pl-4"
                      placeholder="123"
                      value={state.cvv}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length < 4) {
                          setState({
                            ...state,
                            cvv: value,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>)}
            {paymentMethod === "pix" && (
              <>

                <div
                  className={`btn-1 ${loading ? "bg-white" : "bg-secondary"
                    } btn-effects text-primary font-VolksDemiBold text-base ml-auto mt-4`}
                  onClick={() => {
                    onSubmitPix();
                  }}
                >
                  {loading ? (
                    <img
                      className="h-full bg-transparent"
                      src={loaderGif}
                    />
                  ) : (
                    <span>gerar PIX</span>
                  )}
                </div>
                {/* exibe a imagem que esta em base64 */}
                {pixResult?.pix && (
                  <>
                    <img src={`data:image/png;base64,${pixResult.pix.encodedImage}`} className="w-1/2 rounded-ftxl p-2" alt="pix" />
                    <input type="text" className="input-classic w-full text-black pl-4" placeholder="Chave Pix" defaultValue={pixResult?.pix?.payload || ""} />
                    {/* botão copiar */}
                    <button className={`btn-1 bg-secondary btn-effects text-primary font-VolksDemiBold text-base ml-auto mt-4`} onClick={() => { navigator.clipboard.writeText(pixResult.pix.payload) }}>Copiar</button>
                  </>
                )}
              </>)}

          </div>

          <div className="w-full flex flex-row justify-between items-center mb-8">
            <div className="font-LatoRegular text-sm">Total</div>
            <div className="flex flex-row font-VolksDemiBold text-xl text-white rounded-xl ml-auto text-blue-400">
              <div className="ml-2">{"R$ "}</div>
              {donationAmount}
            </div>
          </div>

          {/* <div className="w-full flex flex-row justify-between items-center mb-8 mt-8">
            <div>
              <input type="checkbox" />
            </div>
            <div>{t("cart.make_transaction_private")}</div>
          </div> */}
          {paymentMethod === "creditCard" && (
            <div className="w-full flex flex-row justify-between items-center mb-8">
              <div>
                <Switch
                  onChange={(_ch) => {
                    setSubState(_ch);
                  }}
                  checked={subState}
                />
              </div>
              <div>{t("donation.monthly_donation")}</div>
            </div>
          )}

          <div
            className={`btn-1 ${loading ? "bg-white" : "bg-secondary"
              } btn-effects text-primary font-VolksDemiBold text-base ml-auto mt-4 mb-5`}
            onClick={() => {
              onSubmitDonation();
            }}
          >
            {loading ? (
              <img
                className="h-full bg-transparent"
                src={loaderGif}
              />
            ) : (

                <span>{(paymentMethod === "pix" && pixResult?.pix) ? t("cause.payment_paid") : t("cause.donation")}</span>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

const mapState = ({ market, cart, profile, auth }) => ({
  currentLang: auth.lang,
  cause: market.selectedCause,
  cart: cart,
  profileData: profile.user || auth.user,
});

const mapDispatch = (dispatch) => ({
  cartPayment: (data) => dispatch(startCartPayment(data)),
  makeDonation: (data) => dispatch(startMakeDonation(data)),
  makePix: (data) => dispatch(startMakePix(data)),
  dispatchSetToastrTxt: (_txt) => dispatch(setToastrTxt(_txt)),
  dispatchSetPix: (_txt) => dispatch(setPixResult()),
  dispatchCheckCurrentUser: () => dispatch(checkCurrentUser()),
  dispatchClearCart: () => dispatch(clearCart()),
});

export default connect(mapState, mapDispatch)(Donation);
