import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { emailSignInStart } from "../redux/Auth/auth.actions";
import { startGetCategories } from "../redux/Market/market.actions";
import { Redirect, useHistory } from "react-router-dom";
import MainHeader from "../components/MainHeader";
import MarketHero from "../components/Market/MarketHero";
import MarketBody from "../components/Market/MarketBody";
import CategoryModal from "../components/Market/CategoryModal";
import Footer from "../components/Footer";
import AuthHeader from "../components/Auth/AuthHeader";
import { checkCurrentUser } from "../redux/Auth/auth.actions";

export const Market = ({
  dispatchStartGetCategories,
  getCurrentUser,
  user,
}) => {
  const history = useHistory();

  // logged in user marketplace
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState();
  const [goodsOrCauses, setGoodsOrCauses] = useState();
  const [searchResults, setSearchResults] = useState([]);

  const closeSearchDiv = (event) => {
    // if (searchResDivRef.current && !searchResDivRef.current.contains(event.target)) {
    // setTimeout(() => {
    setSearchResults();
    // }, 2400);
    // }
  };

  useEffect(() => {
    document.title = "magikk - marketplace";
    if (user) {
      getCurrentUser();
    }
    dispatchStartGetCategories();

    const isCategoryRoute = history.location?.state?.data === "open";

    if (isCategoryRoute) {
      setCategoryModalOpen(true);
    }

    if (localStorage.getItem("newuser")) {
      history.push("/set-sustainable-goals");
      localStorage.removeItem("newuser");
    }
  }, []);

  return (
    <div
      className="bg-primary"
      onClick={() => {
        setSearchResults();
      }}
    >
      {localStorage.getItem("token") ? (
        <MainHeader bg={"dark"} />
      ) : (
        <AuthHeader bg={"dark"} />
      )}
      <MarketHero
        selectedCategoryName={selectedCategoryName}
        setCategoryModalOpen={setCategoryModalOpen}
        setSelectedCategoryName={setSelectedCategoryName}
        setGoodsOrCauses={setGoodsOrCauses}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        // searchResDivRef={searchResDivRef}
      />
      <MarketBody
        selectedCategoryName={selectedCategoryName}
        goodsOrCauses={goodsOrCauses}
        closeSearchDiv={closeSearchDiv}
      />
      <Footer />
      <CategoryModal
        setSelectedCategoryName={setSelectedCategoryName}
        categoryModalOpen={categoryModalOpen}
        setCategoryModalOpen={setCategoryModalOpen}
        goodsOrCauses={goodsOrCauses}
        setGoodsOrCauses={setGoodsOrCauses}
      />
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchStartGetCategories: () => dispatch(startGetCategories()),
  getCurrentUser: () => dispatch(checkCurrentUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Market);
