import React from "react";
import { connect } from "react-redux";
import { AuthHeader } from "../../components/Auth/AuthHeader";
import MainHeader from "../../components/MainHeader";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const PrivacyPolicy = ({ user }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  
  document.title = "magikk - políticas de privacidade";

  return (
    <div className="bg-bodyBg">
      {user ? <MainHeader /> : <AuthHeader />}
      <div
        style={{
          maxWidth: "1000px",
          margin: "auto",
          marginTop: 100,
          alignContent: "center",
          alignItems: "center",
          backgroundColor: "#FFF",
          borderRadius: 40,
          padding: 70,
        }}
      >
        <div style={{ fontSize: 30, marginBottom: 50 }}>
          privacy policy
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          effective date: november 29, 2017
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          PURPOSE OF POLICY. This Privacy Statement (“Privacy
          Statement”) explains how Magikk (“Company,” “us,” “our,” and
          “we”) uses your information and applies to all who use our
          Website. This Privacy Statement is incorporated in our Terms
          of Use, which also apply when you use our Website.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          AGREEMENT OF TERMS. By using the Website, you agree to the
          terms of this Privacy Statement. If you do not agree with
          the practices described in this Privacy Statement, please do
          not provide us with your personal information or interact
          with the Website.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          MODIFICATION OF POLICY. We may modify this Privacy Statement
          at any time. If we make any material changes we will notify
          you by email (sent to the email address specified in your
          account) or by means of notice on the Website prior to the
          change becoming effective. You can determine when this
          Privacy Statement was last revised by referring to the date
          it was “Last Updated” above.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          NOTICE CONCERNING CHILDREN PLEASE NOTE: The Company does not
          intend that any portion of the Website will be accessed or
          by anyone who has not reached the age of majority in the
          state or province in which you reside, and such use is
          prohibited. We will promptly delete information associated
          with any account be obtain actual knowledge is associated
          with a registered user who has not reached the age of
          majority.
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          information collection practices
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          What basic information does Magikk collect?
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          We will collect information, including personal information
          and non-identifying information, when you interact with us
          and the Website, for example when you: access or use the
          Website;
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          <li>
            register, subscribe, or create an account with Magikk;
          </li>
          <li>open or respond to our e-mails;</li>
          <li>
            contact customer service or use other customer support
            tools;
          </li>
          <li>
            visit any page online that displays our ads or content;
          </li>
          <li>
            purchase products or services on or through the Website;
          </li>
          <li>
            connect or link to any Website via social networking apps;
            and
          </li>
          <li>provide information to Merchants.</li>
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          This Privacy Statement does not apply to the collection of
          information in any way other than as listed above.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          NOTICE CONCERNING CHILDREN PLEASE NOTE: The Company does not
          intend that any portion of the Website will be accessed or
          by anyone who has not reached the age of majority in the
          state or province in which you reside, and such use is
          prohibited. We will promptly delete information associated
          with any account be obtain actual knowledge is associated
          with a registered user who has not reached the age of
          majority.
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          are there choices to limit the data the company collects
          through the website?
        </div>

        <div style={{ fontSize: 16, marginBottom: 20 }}>
          We think that you benefit from a more personalized
          experience when we know more about you and what you like.
          However, you can limit the information you provide to us
          through Magikk, and you can limit the communications that we
          send to you through Magikk.You may manage how your mobile
          device shares certain Website Data with Magikk by adjusting
          the privacy and security settings on your mobile device.
          Please refer to instructions provided by your mobile service
          provider or the manufacturer of your device to learn how to
          adjust your settings.
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          use and sharing of information
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          What does the company do with collected information?
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          We (or our Vendors on our behalf), use information collected
          as described in this Privacy Statement to:
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          <li>
            Operate, maintain and improve the Website and our
            services, and Other Programs;
          </li>
          <li>
            Facilitate and fulfill transactions placed on the Website
            and Other Programs – for example, for Magikk goods and
            services, including tracking redemption;
          </li>
          <li>
            Evaluate your eligibility for certain types of offers,
            products or services that may be of interest to you, and
            analyze advertising effectiveness;
          </li>
          <li>Answer your questions and respond to your requests;</li>
          <li>Perform analytics and conduct customer research;</li>
          <li>
            Communicate and provide additional information that may be
            of interest to you about Magikk and our Business Partners,
            sometimes by combining your information with information
            from Other Sources;
          </li>
          <li>
            Send you reminders, technical notices, updates, security
            alerts, support and administrative messages, service
            bulletins, marketing messages, and requested information,
            including on behalf of Business Partners;
          </li>
          <li>
            Manage our everyday business needs, such as administration
            of the Website, forum management, fulfillment, analytics,
            fraud prevention, and enforcement of our corporate
            reporting obligations and Terms of Use, or to comply with
            the law;
          </li>
          <li>
            Enhance other information we have about you directly or
            from Other Sources to help us better understand you and
            determine your interests.
          </li>
          <li>
            We also may use information collected as described in this
            Privacy Statement with your consent or as otherwise
            required or permitted by law.
          </li>
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          when and why would the company disclose information i
          provide?
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          IDENTIFIABLE MATERIAL. We (or our Merchants on our behalf)
          may share your personal information as required or permitted
          by law:
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          <li>
            With any affiliate of the Company who may only use the
            personal information for the purposes described in this
            Privacy Statement;
          </li>
          <li>
            With our Vendors to provide services for us and who are
            required to protect the personal information;
          </li>
          <li>
            To report or collect on debts owed to us or our Business
            Partners;
            <li
              class="p-list__item"
              style={{ listStyleType: "circle", marginLeft: 20 }}
            >
              With whom we jointly offer products and services;
            </li>
            <li
              class="p-list__item"
              style={{ listStyleType: "circle", marginLeft: 20 }}
            >
              To facilitate a direct relationship with you, including
              in connection with any program we administer on behalf
              of the Business Partner;
            </li>
            <li
              class="p-list__item"
              style={{ listStyleType: "circle", marginLeft: 20 }}
            >
              To enable electronic communications with you as part of
              purchase, a sponsored reward, offer, contest, program,
              or other activity in which you have elected to
              participate;
            </li>
            <li
              class="p-list__item"
              style={{ listStyleType: "circle", marginLeft: 20 }}
            >
              To the extent you have purchased Magikk goods or
              services offered by a Business Partner or participated
              in an offer, rewards, contest or other activity or Other
              Programs sponsored or offered through Magikk on behalf
              of that Business Partner;
            </li>
          </li>
          <li>
            With a purchaser of the Company or any of the Company’s
            Affiliates (or their assets);
          </li>
          <li>
            To comply with legal orders and government requests, or as
            needed to support auditing, compliance, and corporate
            governance functions;
          </li>
          <li>
            To combat fraud or criminal activity, and to protect our
            rights or those of our Affiliates, users, and Business
            Partners, or as part of legal proceedings affecting the
            Company;
          </li>
          <li>
            In response to a subpoena, or similar legal process,
            including to law enforcement agencies, regulators, and
            courts in the United States and other countries where we
            operate; or
          </li>
          <li>With your consent.</li>
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          We encourage Business Partners to adopt and post privacy
          policies. However, their use of personal information
          obtained through Magikk is governed by their privacy
          policies and is not subject to our control.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          NON-IDENTIFIABLE MATERIAL. We may also disclose
          Non-Identifiable Information:
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          <li>
            For the same reasons we might share personal information;
            or
          </li>
          <li>
            With Business Partners for their own analysis and
            research;
          </li>
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          how does magikk collect data?
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          PERSONAL INFORMATION. You provide us with your personal
          information when you register, subscribe, create an account,
          make a purchase, or otherwise when you provide us with your
          personal information during your interaction with the
          Website and Other Programs. We also collect personal
          information when you contact us online for customer service
          and other support using self-help tools, such as email,
          text, or by posting to an Online Community.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          We also receive personal information and other online and
          offline information from Other Sources. The Company will use
          such information in accordance with applicable laws. Such
          information, when combined with personal information
          collected as provided in this Privacy Statement, will also
          be handled in accordance with this Privacy Statement. We
          also use cookies, tags, web beacons, local shared objects,
          files, tools and programs to keep records, store your
          preferences, improve our advertising, and collect
          Non-Identifying Information, including Website Data and your
          interaction with the Website and our Business Partners'
          websites.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          WEBSITE DATA. Website Data may be collected when your device
          interacts with the Website, even if you are not logged into
          the Website. If you have questions about the security and
          privacy settings of your web browser, please refer to
          instructions from your software provider to learn how to
          adjust your settings.
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          can users access and correct personal information?
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          You can access, update and delete your personal information
          you provided to us by managing this information through your
          Magikk account.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          If you want to deactivate your Magikk account or have other
          questions or requests, please contact us. While we are ready
          to assist you in managing your subscriptions, deactivating
          your account, and removing your active profile, we cannot
          always delete records of past interactions and transactions.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          We will retain your personal information for as long as your
          account is active or as needed to provide you services and
          to maintain a record of your transactions for financial
          reporting purposes. If you wish to deactivate your account
          or request that we no longer use your personal information
          to provide you services contact us. We will retain and use
          your personal information as necessary to comply with our
          legal obligations, resolve disputes, and enforce our
          agreements.
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          how does magikk use social networks to obtain data?
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          You may log in to the Website using certain social network
          credentials. To manage the sharing of certain personal
          information with us when you connect with us through social
          networking platforms or applications refer to the privacy
          policy and settings of the social networking webapp or
          application.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          You also have the option to link social networks, such as
          Facebook, to your Magikk account. Once you register with the
          Company using Magikk and connect with the social network,
          you will be able to automatically post recent Magikk
          activity back to your social network.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          By proceeding through any of the above steps, you grant the
          Company permission to access all of the elements of your
          social network profile information that you have made
          available to be shared and to use it in accordance with the
          social network’s terms of use and this Privacy Statement.
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          third parties.
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          This Privacy Statement only addresses the use and disclosure
          of information by the Company through your interaction with
          the Website. Other Websites that may be accessible through
          links from the Website and Other Programs may have their own
          privacy statements and personal information collection, use,
          and disclosure practices. Our Business Partners may also
          have their own privacy statements. We encourage you to
          familiarize yourself with the privacy statements provided by
          these other parties prior to providing them with information
          or taking advantage of a sponsored offer or promotion.
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          contact information and policy updates
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          CONTACTING US. If you have any questions about this Policy,
          our practices related to this Website, or if you would like
          to have us remove your information from our database please
          feel contact us at the following: contact@magikk.com
        </div>
        <div style={{ fontSize: 24, marginBottom: 20 }}>
          updates and changes
        </div>
        <div style={{ fontSize: 16, marginBottom: 20 }}>
          We reserve the right, at any time, to add to, change,
          update, or modify this Policy. If there are any material
          changes to this Privacy Policy, the Company will notify you
          by email, by means of a notice on this Website or as
          otherwise required by applicable law, the Company encourages
          you to review this Policy periodically to be informed
          regarding how the Company is using and protecting your
          information and to be aware of any policy changes. Any such
          change, update, or modification will be effective
          immediately upon posting on the Website. It is your
          responsibility to review this Policy from time to time to
          ensure that you continue to agree with all of its terms.
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicy);
